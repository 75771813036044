import { useCallback, useMemo, useRef } from "react";

import { sendMirrorCustomEvent } from "shared/components/tracking/mirror";

import type { UseParsedCookieOption } from "scmp-app/lib/hooks";
import { useParsedCookie } from "scmp-app/lib/hooks";
import { isString } from "scmp-app/lib/utils";

import { Edition, editionOptions } from "./data";

export * from "./data";

const isValidEdition = (edition: string): edition is Edition =>
  Object.values(Edition).includes(edition as Edition);

export function useEdition(option?: UseParsedCookieOption) {
  const { update: updateCookie, value } = useParsedCookie("scmp_edition", raw => raw, {
    ...option,
    expires: 400, // Max value is 400 days
  });
  const isInitialHasSelected = useRef(value !== null);

  const get = useCallback(
    () => (isString(value) && isValidEdition(value) ? value : Edition.International),
    [value],
  );

  const getOptions = useCallback(() => editionOptions, []);

  const update = useCallback(
    (value: string) => {
      if (!isValidEdition(value)) return;

      sendMirrorCustomEvent({
        edition: value,
        event_name: "edition_preference",
      });
      updateCookie(value);
    },
    [updateCookie],
  );

  return {
    get,
    getOptions,
    isInitialHasSelected: isInitialHasSelected.current,
    update,
  };
}

export function useEditionValue(option?: UseParsedCookieOption): Edition {
  const { get } = useEdition(option);
  const value = useMemo(() => get(), [get]);
  return value;
}
